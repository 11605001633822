<template>
  <b-sidebar
    id="SidebarFilterTransaction"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
    width="85vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>

      <div class="mt-3">
        <label class="mb-2">Start Date - End Date</label>
        <div class="date-input">
          <span
            v-if="!filter.start_date"
            @click="$refs.dateStart.isOpen = true"
            class="w-40 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateStart.isOpen = true"
            class="w-50 pointer"
            >{{ filter.start_date | moment($formatDateNew) }}</span
          >
          <span class="px-2">-</span>
          <span
            v-if="!filter.end_date"
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >DD/MM/YYYY</span
          >
          <span
            v-else
            @click="$refs.dateEnd.isOpen = true"
            class="w-100 pointer"
            >{{ filter.end_date | moment($formatDateNew) }}</span
          >
          <div :class="'icon-primary text-right'">
            <font-awesome-icon
              icon="calendar-alt"
              @click="$refs.dateStart.isOpen = true"
              :class="'cursor-pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
        <datetime
          ref="dateStart"
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          v-model="filter.start_date"
          :max-datetime="filter.end_date || dateFormat()"
        >
        </datetime>
        <datetime
          type="date"
          format="dd/MM/yyyy"
          value-zone="Asia/Bangkok"
          :max-datetime="dateFormat()"
          :min-datetime="filter.start_date"
          ref="dateEnd"
          v-model="filter.end_date"
        >
        </datetime>
      </div>
      <div class="mt-3">
        <InputSelect
          title="Branch"
          name="branch"
          :options="branch"
          :value="filter.branch_id"
          valueField="id"
          textField="name"
          :noPleaseSelect="true"
          @onDataChange="handleChangeType"
        />
      </div>
      <div class="my-3">
        <label class="main-label">Source name</label>
        <multiselect
          :close-on-select="false"
          :clear-on-select="false"
          multiple
          v-model="selected"
          :options="saleChanel"
          placeholder="Search or Select channal"
          track-by="id"
          label="name"
        ></multiselect>
      </div>
      <div class="my-3">
        <label class="main-label">Status</label>
        <multiselect
          :close-on-select="false"
          :clear-on-select="false"
          multiple
          v-model="selectedStatus"
          :options="statusFilter"
          placeholder="Select Status"
          track-by="id"
          label="name"
        ></multiselect>
      </div>
      <div class="my-3">
        <label class="main-label">Action</label>
        <multiselect
          :close-on-select="false"
          :clear-on-select="false"
          multiple
          v-model="actionSelect"
          :options="actionList"
          placeholder="Select Status"
          track-by="id"
          label="action_name"
        ></multiselect>
      </div>
      <div class="mb-3">
        <MultiSelect
          :used="false"
          textFloat="Birth Month"
          :options="birthMonth"
          textField="text"
          valueField="value"
          placeholder="Select Birth Month"
          v-model="filter.birth_month"
          :value="filter.birth_month"
        />
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import Multiselect from "vue-multiselect";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputSelect,
    Multiselect,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    branch: {
      required: true,
      type: Array,
    },
    actionList: {
      required: true,
      type: Array,
    },
    saleChanel: {
      required: true,
      type: Array,
    },
    statusFilter: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      selected: [],
      allSelected: false,
      allSelectedStatus: false,
      selectedStatus: [],
      actionSelect: [],
      birthMonth: [],
    };
  },
  watch: {},
  created() {
    let i = 1;
    for (const month of this.$moment.months()) {
      this.birthMonth.push({ text: month, value: i });
      i++;
    }
  },
  methods: {
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    handleChangeType(value) {
      this.filter.branch_id = value;
    },
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    toggleAll(checked) {
      if (checked) {
        this.selected = [2, 1];
      } else {
        this.selected = [];
      }
    },

    clearFilter() {
      this.filter.search = "";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.selected = [];
      this.selectedStatus = [];
      this.filter.birth_month = [];
      this.filter.branch_id = 0;
      this.filter.source_id = null;
      this.filter.status_id = null;
      this.$emit("submitFilter", this.filter);
      this.hide();
    },
    submitAction() {
      this.filter.page = 1;
      this.filter.source_id = this.selected.map((el) => el.id);
      this.filter.status_id = this.selectedStatus.map((el) => el.id);
      this.filter.action_id = this.actionSelect.map((el) => el.id);

      this.$emit("submitFilter", this.filter);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.date-input {
  border: 1px solid #d8dbe0;
  color: #768192;
  background-color: #fff;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep .vdatetime-input {
  display: none;
}
.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.w-40 {
  width: 40%;
}

.form-group {
  margin-bottom: 0.5rem;
}
</style>
